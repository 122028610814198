import React from 'react';
import { Box, Heading, Text, RadioGroup, Radio, Stack } from '@chakra-ui/react';

const customRadioStyle = {
  _checked: {
    bg: 'green.400',
    borderColor: 'green.400',
    color: 'white',
  },
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    bg: 'green.100',
    borderColor: 'green.300',
  },
};

export function Introduction({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Welcome to Dr Billerz!</Heading>
      <Text mb={4} fontSize="lg" fontFamily="Arial, sans-serif">
        Hello! Let's get started by finding out more about you.
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="billing-company" sx={customRadioStyle}>
            Billing company
          </Radio>
          <Radio value="medical-provider" sx={customRadioStyle}>
            Medical provider
          </Radio>
          <Radio value="medical-group-representative" sx={customRadioStyle}>
            Medical group representative
          </Radio>
          <Radio value="job-seeker" sx={customRadioStyle}>
            Job seeker
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function RoleInterest({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Fantastic! Let's Dive Deeper</Heading>
      <Text mb={4} fontSize="lg">
        Which of these best describes your role or interest?
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="billing-company" sx={customRadioStyle}>
            I'm a billing company looking hire billers or build a billing team
          </Radio>
          <Radio value="medical-provider" sx={customRadioStyle}>
            I'm a healthcare provider looking to hire a medical biller
          </Radio>
          <Radio value="medical-group" sx={customRadioStyle}>
            I'm part of a medical group looking for streamlined billing solutions
          </Radio>
          <Radio value="job-seeker" sx={customRadioStyle}>
            I'm exploring opportunities to join a dynamic billing team
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function Experience({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Experience Matters</Heading>
      <Text mb={4} fontSize="lg">
        What level of experience are you looking for in a medical biller?
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="entry" sx={customRadioStyle}>
            Entry level
          </Radio>
          <Radio value="intermediate" sx={customRadioStyle}>
            Intermediate level
          </Radio>
          <Radio value="advanced" sx={customRadioStyle}>
            Advanced level
          </Radio>
          <Radio value="not-sure" sx={customRadioStyle}>
            Not sure
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function PaymentPreference({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Payment Preference</Heading>
      <Text mb={4} fontSize="lg">
        How would you prefer to pay?
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="hourly" sx={customRadioStyle}>
            Hourly
          </Radio>
          <Radio value="fixed-price" sx={customRadioStyle}>
            Fixed price
          </Radio>
          <Radio value="not-sure" sx={customRadioStyle}>
            Not sure
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function ServiceTimeline({ selectedOption, onOptionChange }) {
  return (
    <Box>
      <Heading mb={6}>Service Timeline</Heading>
      <Text mb={4} fontSize="lg">
        How soon do you need the services?
      </Text>
      <RadioGroup value={selectedOption} onChange={onOptionChange}>
        <Stack spacing={4}>
          <Radio value="immediate" sx={customRadioStyle}>
            Immediate
          </Radio>
          <Radio value="within-1-month" sx={customRadioStyle}>
            Within 1 month
          </Radio>
          <Radio value="within-3-months" sx={customRadioStyle}>
            Within 3 months
          </Radio>
          <Radio value="flexible" sx={customRadioStyle}>
            Flexible
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  );
}

export function FinalScreen() {
  return (
    <Box>
      <Heading mb={6}>You're All Set!</Heading>
      <Text mb={4} >
      Thank you for providing your details. You're now one step closer to building your dream in-house billing team with Dr Billerz. Our medical billing manager will review your requirements and get in touch with you shortly.
      </Text>
    </Box>
  );
}
